import axios from 'axios'
import { endpoints } from '@/config/app'
import { getSession } from 'next-auth/react';

const createHttp = () => {
	const config = {
		baseURL: endpoints.baseURL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	}

	const http = axios.create(config)

	http.interceptors.request.use(
		async(request) => {

			const session = await getSession();
			if (session) {
				// @ts-ignore
			 	 request.headers.Authorization = `token ${session.user.token}`;
			}

			return request
		},
		(error) => Promise.reject(error)
	)

	http.interceptors.response.use(
		(response) => {
			return response.data
		},
		(error: any) => {
			let messages: string[] = []
			if (error.response.data) {
				const errors = error.response.data
				// @ts-ignore
				messages = Object.entries(errors).map(([field, errors]) => `${field}: ${errors[0]}`)
			}

			return Promise.reject(error.message + ', ' + messages.join(','))
		}
	)

	return http
}

export default createHttp()
