export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const GTMPageView = (url: string) => {
	const pageEvent = {
		event: 'pageview',
		page: url,
	}
	//console.log('pageEvent::', pageEvent)
	//@ts-ignore
	window && window.dataLayer && window.dataLayer.push(pageEvent)

	return pageEvent
}
