import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AppState, AppThunk } from '@/app/store'
import { fetchProducts } from '@/services/products.service'
import { ProductsState } from '@/types/products'
import { registerCustomer } from '@/features/register/registerSlice'
import { CustomerResponse } from '@/types/register'

const initialState: ProductsState = {
	status: 'idle',
	requestId: undefined,
	data: [],
	error: null,
}

export const getProductsAsync = createAsyncThunk('products/fetchProducts', async (customer: CustomerResponse) => {
	// The value we return becomes the `fulfilled` action payload
	const products = await fetchProducts({
		is_order: true,
		neighborhood: customer.suburb,
		code_zip: customer.code_postal,
	})

	return products
})

export const productsSlide = createSlice({
	name: 'products',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getProductsAsync.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(getProductsAsync.fulfilled, (state, action) => {
				state.status = 'idle'
				state.data = action.payload
			})
			.addCase(registerCustomer.rejected, (state, action) => {
				if (state.requestId === action.meta.requestId && state.status === 'pending') {
					state.status = 'idle'
					state.error = action.error as any
					state.requestId = undefined
					state.data = []
				}
			})
	},
})

export const selectProducts = (state: AppState) => state.products

export default productsSlide.reducer
