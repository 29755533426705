import { Product, ProductResponse } from '@/types/products'
import http from '@/services/http'
import { endpoints } from '@/config/app'
import { stringify } from 'query-string'

export type PriceListItem = {
	name: string
	products: ProductResponse[]
}

export type Props = {
	is_order?: boolean
	is_generic?: boolean
	is_range?: boolean
	neighborhood: string
	code_zip: string
}

export async function fetchProducts(options: Props): Promise<Product[]> {
	let products: Product[] = []
	try {
		const response: PriceListItem = await http.get(endpoints.products, {
			params: options,
			paramsSerializer: (params) => stringify(params, { arrayFormat: 'none', skipEmptyString: true }),
		})
		if (response && response?.products) {
			products = response.products.map((item: ProductResponse) => {
				return {
					...item,
					imageUrl: '/assets/imgs/product-20L.png',
				}
			}) as Product[]
		}


		const listProductsWithOut = products.map(products=> products).filter(products => products.price>0)

		return listProductsWithOut
	} catch (error: any) {
		throw error
	}
}
