import Link from 'next/link'
import type { NetworksType } from '@/layouts/MainLayout'
import SocialIcons from '@/components/SocialIcons/SocialIcons'

type FooterProps = {
	networks: NetworksType
}

const Footer = ({ networks }: FooterProps): JSX.Element => {
	const withSocialLinks = false
	return (
		<div className="footer pb-8   h-[150px] bg-[#021C49] ">
			{/* place-content-center text-center  flex */}

			{/* <div className="header flex flex-col md:flex-row justify-between w-full  m-auto ">
				<div
					className='bg-[url("/assets/imgs/logo_footer_20_years.png")] bg-no-repeat p-3 mx-auto   place-items-center   md:w-48 mb-2  m-auto'
					style={{ width: 175, height: 170 }}
				></div>
			</div> */}
			<div className="flex flex-col md:flex-col  justify-around  w-full    bg-[#021C49]  m-auto ">
				<div
					className='logo-footer bg-[url("/assets/imgs/logo_footer.png")] bg-no-repeat p-3 mx-auto   place-items-center   md:w-48 mb-2  m-auto mt-[3rem] md:mt-'
					style={{ width: 122, height: 63 }}
				></div>
				<div className="m-auto  mb-4">
					<Link href="/privacy" replace={false}>
						<a className=" text-sm text-white  hover:text-white ">
							Aviso de privacidad.
						</a>
					</Link>
				</div>
				<div className=" m-auto mb-4">
					<Link href="/privacy" replace={false}>
						<a className="text-sm text-white  hover:text-white">
							Todos los derechos reservados <br /> Embotelladora DION S.A de C.V.
						</a>
					</Link>
				</div>

				
				{withSocialLinks && <SocialIcons networks={networks} />}
			</div>
		</div>
	)
}

export default Footer
