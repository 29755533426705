import { Button as AntButton } from 'antd'
import { motion } from 'framer-motion'
import React from 'react'

const Button = ({ children, ...props }: any) => {
	const inputRef = React.useRef(null)
	return (
		<motion.div ref={inputRef} whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}>
			<AntButton type="primary" {...props}>
				{children}
			</AntButton>
		</motion.div>
	)
}

export default Button

