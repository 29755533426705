const SERVICE_URL = process.env.NEXT_PUBLIC_SERVICE_URL

const endpoints = {
	baseURL: SERVICE_URL + '/api/v1/',

	products: 'prices-list/',
	product: 'price-list/',
	coverages: 'coverages/',
	customers: 'customers/',
	customers_price_list: 'customers-price-list/',
	customers_price_list_customer_public_general: `customers-price-list/?slug=${process.env.NEXT_PUBLIC_CUSTOMER_PUBLIC_IN_GENERAL_SLUG}`,
	orders: 'orders/',
	orders_delivery: 'sale/',
	smsCode: 'sms-code/',
	verifyCode: 'verify-code/',
	smsCodeCampaing :'verify_campaign_no_customer/',
	reSmsCodeCampaing :'resend_no_customer/',
	verifyCampaign :'verify-campaign/',
	verifyViability: 'verify-viability/',
	apartments: 'apartment/',
	billing: 'billing/',
	states: 'estados/',
	state: 'estado',
	zipCodes:'zip_codes/',
	zipCode:'zip_code',
	sales_summary :'sales-summary/',
	team_sales_summary :'team-sales-summary/'

}

const paths = {
	dashboard: {
		path: '/dashboard/',
		title: 'Dashboard',
	},
	register: {
		basePath: '/',
		customer: {
			path: '/register/customer',
			title: 'Cliente',
		},
		confirm: {
			path: '/register/confirm',
			title: 'Confirmación',
		},
		summary: {
			path: '/register/summary',
			title: 'Resumen',
		},
	},
	orders: {
		path: '/orders',
		title: 'Pedidos',
	},
	orders_delivery: {
		path: '/orders/delivery/create/',
		title: 'Venta',
	},
}

// @pages/login.tsx will pass to this default path to <Login/>
// if callbackUrl is not present in the current url as query param GET
const defaultRedirect = paths.dashboard.path

const modalProps = {
	icon: null,
	okText: null,
	keyboard: false,
	centered: true,
	okButtonProps: {
		hidden: true,
	},
	footer: null,
	width: 520,
	maskStyle: {
		backgroundColor: 'rgba(0,0,0,.62)',
	},
}

const phoneRegex =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const EXPIRATION_COOKIE_DAYS = 1
const EXPIRATION_COOKIE_MINUTES = 30

enum SERVICE_TYPES {
	GENDER = 'GENDER',
	RANGER = 'RANGER',
}

export const GENERIC_TYPE = 'GENDER'

const SEO_DATA = {
	canonical: 'https://preferente.vaissen.com/',
	apiUrl: {
		uri: process.env.NEXT_PUBLIC_SERVICE_URL,
	},
	meta: {
		rootUrl: process.env.NEXT_PUBLIC_SITE_URL,
		title: 'Garrafón al hogar',
		description: 'Agua purificada, directo a tu hogar',
		keywords: 'agua, hogar, entrega a domicilio, puebla, cdmx',
		social: {
			graphic: '/vaissen.png',
			twitter: '@vaissen',
		},
	},
	opengraph: {
		site: 'https://preferente.vaissen.com/',
		image: '/vaissen.png',
		type: 'website'
	},
	icons: {
		'64x64': {
			src: '/icons/icon-64x64.png',
			sizes: '64x64',
			type: 'image/png',
		},
	},
	themeColor: '#7C7C7C',
	routes: {
		authenticated: {
			pathAfterFailure: '/login',
		},
	},
}

export {
	endpoints,
	paths,
	modalProps,
	EXPIRATION_COOKIE_DAYS,
	EXPIRATION_COOKIE_MINUTES,
	phoneRegex,
	SERVICE_TYPES,
	SEO_DATA,
	defaultRedirect,
}
