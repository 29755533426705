import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import orderReducer from '@/features/orders/ordersSlice'
import productsReducer from '@/features/products/productsSlice'
import preferencesReducer from '@/features/preferences/preferencesSlice'
import registerReducer from '@/features/register/registerSlice'

export function makeStore() {
	return configureStore({
		devTools: process.env.NODE_ENV !== 'production',
		reducer: {
			preferences: preferencesReducer,
			products: productsReducer,
			register: registerReducer,
			order: orderReducer,
		},
	})
}

const store = makeStore()

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>

export default store
