import { createSlice } from '@reduxjs/toolkit'
import { AppState } from '@/app/store'

export const initialState = {
	status: 'idle',
	data: {
		apartments: {
			OWN: 'Casa propia',
			SUBDIVUSION: 'Fraccionamiento', // SUBDIVISION
			DEPARTMENT: 'Departamento',
			OTHER: 'Otro',
		},
		contact: {
			WHATSAPP_MESSAGE: 'Mensaje WhatsApp',
			PHONE_CALL: 'Llamada celular o teléfono',
			EMAIL: 'Email',
		},
		type: {
			CUSTOMER: 'Cliente',
			PROSPECT: 'Prospecto',
		},
		gender: {
			female: 'Mujer',
			male: 'Hombre',
		},
		services: {
			// RANGER: {
			// 	label: 'Entrega Preferente',
			// 	options: [
			// 		{ start_time: '08:00', final_time: '09:00' },
			// 		{ start_time: '09:00', final_time: '10:00' },
			// 		{ start_time: '10:00', final_time: '11:00' },
			// 		{ start_time: '11:00', final_time: '12:00' },
			// 		{ start_time: '12:00', final_time: '13:00' },
			// 		{ start_time: '13:00', final_time: '14:00' },
			// 		{ start_time: '14:00', final_time: '15:00' },
			// 		{ start_time: '15:00', final_time: '16:00' },
			// 		{ start_time: '16:00', final_time: '17:00' },
			// 		{ start_time: '17:00', final_time: '18:00' },
			// 		{ start_time: '18:00', final_time: '19:00' },
			// 	],
			// },
			GENDER: {
				label: 'Entrega Normal',
				options: [
					{ start_time: '09:00:00', final_time: '15:00:00' },
					{ start_time: '15:00:00', final_time: '19:00:00' },
				],
			},
		},
		meet: {
			SOCIAL_NETWORKS: 'Redes sociales',
			RECOMMENDATION: 'Recomendación',
			DELIVERY_MAN: 'Vi la camioneta en la calle',
		},
		status: {
			PROSPECT: 'Prospecto',
			IN_PROCESS: 'En proceso de validación',
			VALIDATED: 'Validado',
		},
		days: {
			MONDAY: 'Lunes',
			TUESDAY: 'Martes',
			WEDNESDAY: 'Miércoles',
			THURSDAY: 'Jueves',
			FRIDAY: 'Viernes',
			SATURDAY: 'Sábado',
			SUNDAY: 'Domingo',
		},
		VISIT_FREQUENCY : {
			'WEEKLY': 'Semanal',
			'FORTNIGHTLY': 'Quincenal'
		}
	},
}

export const preferencesSlice = createSlice({
	name: 'preferences',
	initialState,
	reducers: {},
	extraReducers: (builder) => {},
})

export const selectPreferences = (state: AppState) => state.preferences

export default preferencesSlice.reducer
