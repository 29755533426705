import {Badge, Layout, Tooltip, Dropdown, Space} from 'antd'
import Link from '../NoScrollLink'
import {AiOutlineShoppingCart} from 'react-icons/ai'
import {paths} from '@/config/app'
import {useAppSelector} from '@/app/hooks'
import {selectOrders} from '@/features/orders/ordersSlice'
import {useEffect, useMemo} from 'react'
import Button from '@/ui/Button'
import {Menu} from 'antd'
import {url} from 'inspector'
import AuthUser, {getMenuItem} from '@/components/AuthUser'
import {AppstoreOutlined, DatabaseTwoTone, SettingOutlined, MoreOutlined, PoweroffOutlined} from '@ant-design/icons'
import {size} from 'lodash'
import {Drawer} from 'antd'
import {useState} from 'react'
import {PlusOutlined} from '@ant-design/icons'
import {isMobile} from 'react-device-detect'
import {signOut} from 'next-auth/react'
import {useRouter} from 'next/router'

type Props = {
    session: any
    logoUrl: string
}

const Header = ({logoUrl, session}: Props) => {
    const [visible, setVisible] = useState(false)
    const router = useRouter()
    // NOTE:: with this url social networks and purchases will be hidden
    const url_route = `/login?callbackUrl=${paths.dashboard.path}`

    const handleClick = () => {
        signOut({
            callbackUrl: process.env.NEXT_PUBLIC_LOGIN_PATH,
        })
    }


    const {
        payload: {products},
    } = useAppSelector(selectOrders)

    const qty = useMemo(() => {
        const qty = Object.values(products).reduce((carry: number, current: any) => {
            return carry + current.quantity
        }, 0)

        return qty
    }, [products])

    const itemsSession = [
        getMenuItem(
            <div key="item-1">
                <div className="text-gray-700 mb-1">{session?.user.name}</div>
                <div className="text-gray-400 mb-1">{session?.user.username}</div>
                <div
                    className="flex items-center gap-2 "
                    onClick={() => {
                        signOut({
                            callbackUrl: process.env.NEXT_PUBLIC_LOGIN_PATH,
                        })
                    }}
                >
                    <PoweroffOutlined className="text-red-500"/>
                    <span className="text-red-500">Cerrar sesión</span>
                </div>
            </div>,
            'item-1'
        ),
    ]

    const items = [
        getMenuItem(
            <div key="item-1">
                <div className="text-gray-700">
                    <a href={paths.orders.path + '/new-order'}>soy cliente, quiero pedir</a>
                </div>
            </div>,
            'item-1'
        ),
    ]

    const HandlerOnVisibleChange = (e: boolean) => {
        console.log(e)
        setVisible(e)
    }

    return (
        <Layout.Header
            //
            className="header-initial  px-0 h-[64px] drop-shadow-lg "
            style={{position: 'fixed', zIndex: 1, width: '100%'}}
        >
            <div className="container flex justify-around md:justify-between  h-full  ">
                <div className="  ">
                    <Link href="/" replace={true}>
                        <a className="block text-lg font-black hover:text-blue-landing-500 transition-all ease-in-out delay-300 hover:scale-110">
                            <img src={logoUrl} alt="Vaissen" height="auto" width={125}/>
                        </a>
                    </Link>
                </div>

                {/* <Space> */}
                {isMobile ? (
                    session ? (
                        <Dropdown
                            trigger={['click']}
                            onVisibleChange={HandlerOnVisibleChange}
                            visible={visible}
                            className="text-[#021C49] drop-down"
                            overlay={<Menu items={itemsSession}/>}
                            placement="bottomRight"
                        >
                            <Button
                                className="button-dropdown text-[#021C49] "
                                icon={<MoreOutlined style={{fontSize: '24px', color: '#BABABA'}}/>}
                            ></Button>
                        </Dropdown>
                    ) : !url_route &&  (
                        <Link href={paths.orders.path + '/new-order'}>
                            <Tooltip title="Realizar pedido">
                                <Badge count={qty} offset={[5, 15]}>
                                    <a className="anchor-new-order   flex items-center justify-center h-full">
                                        <AiOutlineShoppingCart size={24}/>
                                    </a>
                                </Badge>
                            </Tooltip>
                        </Link>
                    )
                ) : session ? (
                    <>{session && <AuthUser session={session}/>}</>
                ) : (
                    <Link href={paths.orders.path + '/new-order'}>
                        <Button className=" text-truncate button-header font-bold py-2 px-4 ">
                            Soy cliente, quiero pedir
                        </Button>
                    </Link>
                )}
                {/* </Space> */}
            </div>
        </Layout.Header>
    )
}

export default Header
