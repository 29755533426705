import { message } from 'antd'
import * as R from 'ramda'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AppState, AppThunk } from '@/app/store'
import { OrderPayload, OrderState } from '@/types/order'
import * as Sentry from '@sentry/nextjs'
import { createOrder } from '@/services/app.service'
import dayjs from 'dayjs'

const initialState: OrderState = {
	status: 'idle',
	requestId: undefined,
	payload: {
		is_order: true,
		phone_number: '',
		date_delivery: '',
		products: {},
	},
	data: null,
	error: null,
}

export const transformPayload = (payload: any, phone_number: string): OrderPayload => {
	const { date_delivery, products } = payload
	return {
		date_delivery,
		phone: phone_number,
		product: Object.values(products),
	}
}

export const registerOrder = createAsyncThunk('orders/createOrder', async ({ phone, date }: any, { getState }) => {
	const onComplete = message.loading('Registrando...', 0)
	try {
		// @ts-ignore
		const order = getState().order

		const payload = transformPayload(order.payload, phone)
		const delivered = dayjs(date)

		if (payload.product.length === 0) {
			throw new Error('Datos inválidos, selecciona los productos por favor.')
		}

		if (phone.length === 0 || !delivered.isValid()) {
			throw new Error('Datos inválidos, verifica que el teléfono o fecha de entrega sean correctos.')
		}

		const response = await createOrder(payload)

		if (response) {
			return response
		}

		return null
	} catch (error: any) {
		message.error(error.message)
		Sentry.captureException(error)
		throw error
	} finally {
		onComplete()
	}
})

export const ordersSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		resetAllData: (state) => {
			state.payload = initialState.payload
			state.status = 'idle'
			state.data = null
			state.error = null
		},
		setFormDataKey: (state, action): any => {
			let nState = state.payload

			nState = {
				...nState,
				[action.payload.key]: action.payload.value,
			}

			state.payload = nState
		},
		setFormData: (state, action): any => {
			let nState = state.payload

			nState = {
				...nState,
				...action.payload,
			}

			state.payload = nState
		},
		addProductToBasket: (state, action): any => {
			let nState = { ...state.payload.products }

			if (nState[action.payload.product]) {
				nState = R.dissoc(action.payload.product, nState)
			} else {
				const item = {
					[action.payload.product]: {
						product: action.payload.product,
						quantity: action.payload.quantity,
						price: action.payload.price,
					},
				}
				nState = R.mergeRight(nState, item)
			}

			state.payload.products = nState
		},
		deleteProduct: (state, action): any => {
			let nState = { ...state.payload.products };
			delete nState[action.payload.product];
			state.payload.products = nState;
		  },
		updateProductQuantity: (state, action): any => {
			let nState = { ...state.payload.products }

			if (nState[action.payload.product]) {
				nState[action.payload.product].quantity = action.payload.quantity
			} else {
				const item = {
					[action.payload.product]: {
						product: action.payload.product,
						quantity: action.payload.quantity,
						price: action.payload.price,
					},
				}
				nState = R.mergeRight(nState, item)
			}

			state.payload.products = nState
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerOrder.pending, (state, action) => {
				if (state.status === 'idle') {
					state.status = 'pending'
					state.requestId = action.meta.requestId
				}
			})
			.addCase(registerOrder.fulfilled, (state, action) => {
				if (state.requestId === action.meta.requestId && state.status === 'pending') {
					state.status = 'idle'
					state.data = action.payload
					state.requestId = undefined
				}
			})
			.addCase(registerOrder.rejected, (state, action) => {
				if (state.requestId === action.meta.requestId && state.status === 'pending') {
					state.status = 'idle'
					state.error = action.error
					state.requestId = undefined
					state.data = null
				}
			})
	},
})

export const { setFormDataKey, setFormData, addProductToBasket, updateProductQuantity,deleteProduct, resetAllData } =
	ordersSlice.actions

export const selectOrders = (state: AppState) => state.order

export default ordersSlice.reducer
