import { Layout } from 'antd'
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer'
import dynamic from 'next/dynamic'
import SCLink from '@/components/SCLink'
import { useEffect } from 'react'
import {paths} from "@/config/app";
const WAppLink = dynamic(() => import('@/components/WAppLink'), {
	ssr: false,
})

export type NetworksType = {
	[name: string]: {
		url: string
		name?: string
	}
}

export type MainLayoutProps = {
	session: any
	children?: JSX.Element
	logoUrl: string
	networks: NetworksType
}

const MainLayout = ({ children, logoUrl, networks, session }: MainLayoutProps): JSX.Element => {
	  // NOTE:: with this url social networks and purchases will be hidden
    const url_route = `/login?callbackUrl=${paths.dashboard.path}`

	return (
		<Layout className="main-layout">
			<Header logoUrl={logoUrl} session={session} />
			{/* mt-[64px] */}
			<main className=" mt-[64px] w-full ">
				<div className="main-content mb-[100px]">{children}</div>
				<Footer networks={networks} />
			</main>
			{!session && !url_route &&  (
				<>
					<WAppLink phoneNumber="5546156329" message="Hola, quiero información acerca de Vaissen" />
					<SCLink phoneNumber="5573065004" />
				</>
			)}
		</Layout>
	)
}

export default MainLayout
