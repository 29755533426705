import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { AppState, AppThunk } from '@/app/store'
import { CustomerPayload, CustomerResponse, RegisterSliceState } from '@/types/register'
import { createCustomer } from '@/services/app.service'
import { transformPayload } from '@/services/customers'
import { message } from 'antd'
import * as Sentry from '@sentry/nextjs'

class PropertyRequiredError extends Error {
	private cause: any

	constructor(message: string, data: any, status: number = 400) {
		super(message)
		this.name = this.constructor.name
		this.cause = {
			status: status,
			payload: JSON.stringify(data),
		}
	}
}

const initialState: RegisterSliceState = {
	status: 'idle',
	requestId: undefined,
	payload: {
		reference: '',
		another_apartment: null,
		apartment_photo: null,
		contact: 'WHATSAPP_MESSAGE',
		meet: '',
		status_register: 'IN_PROCESS',
		address: {
			street: '',
			street_1:'',
			street_2:'',
			reference:'',
			ext_number: '',
			int_number: '',
			colony: '',
			state: '',
			zip_code: '',
			country: '',
			tower_depot_number: '',
		},

		customer: {
			name: '',
			last_paternal_name: '',
			last_maternal_name: '',
			birth_date: '',
			birthday_year: '',
			phone_number: '',
			gender: '',
			type: 'CUSTOMER',
		},

		location: {
			lat: 19.04374081245977,
			lng: -98.1989192491516,
		},

		schedule: {
			first_day_visit: '',
			apartment: null,
			service: 'GENDER',
			hours: {
				start_time: '09:00',
				final_time: '19:00',
			},
			days: [],
		},

		availability: [],
		is_coverage: false,
	},
	data: null,
	error: null,
}

export const registerCustomer = createAsyncThunk(
	'register/createCustomer',
	async (phone_number: string | null, { getState }) => {
		try {
			// @ts-ignore
			const { payload: data }: RegisterState = getState().register
			const payload: CustomerPayload = transformPayload(data)

			if (payload.name.length === 0 && phone_number?.length === 0) {
				throw new PropertyRequiredError('Datos inválidos, el nombre del cliente es requerido.', payload)
			}

			// API Call
			const response: CustomerResponse = await createCustomer(payload)

			if (response?.folio) {
				message.success('Cliente registrado correctamente.')
			}

			return response
		} catch (error: any) {
			if (error?.message) {
				message.error(error.message, 5)
			}

			Sentry.captureException(error)
			throw error
		}
	}
)

export const registerSlice = createSlice({
	name: 'register',
	initialState,
	reducers: {
		resetAllData: (state) => {
			state.payload = initialState.payload
			state.status = 'idle'
			state.data = null
			state.error = null
		},
		setAddressData: (state, action) => {
			state.payload.address = action.payload
		},
		setAddressDataKey: (state, action): any => {
			const nState = {
				...state.payload.address,
				[action.payload.key]: action.payload.value,
			}

			state.payload.address = nState
		},
		setAvailability: (state, action) => {
			state.payload.availability = action.payload
			state.payload.is_coverage = !!action.payload.length
		},
		setGeoLocation: (state, action) => {
			state.payload.location = action.payload
		},
		setScheduleData: (state, action) => {
			const nState = {
				...state.payload.schedule,
				...action.payload,
			}
			state.payload.schedule = nState
		},
		setCustomerDataKey: (state, action) => {
			const nState = {
				...state.payload.customer,
				[action.payload.key]: action.payload.value,
			}

			state.payload.customer = nState
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerCustomer.pending, (state, action) => {
				if (state.status === 'idle') {
					state.status = 'pending'
					state.requestId = action.meta.requestId
				}
			})
			.addCase(registerCustomer.fulfilled, (state, action) => {
				if (state.requestId === action.meta.requestId && state.status === 'pending') {
					state.status = 'idle'
					state.data = action.payload
					state.requestId = undefined
				}
			})
			.addCase(registerCustomer.rejected, (state, action) => {
				if (state.requestId === action.meta.requestId && state.status === 'pending') {
					state.status = 'idle'
					state.error = action.error as any
					state.requestId = undefined
					state.data = null
				}
			})
	},
})

export const {
	resetAllData,
	setAddressData,
	setAvailability,
	setAddressDataKey,
	setGeoLocation,
	setScheduleData,
	setCustomerDataKey,
} = registerSlice.actions

export const selectRegister = (state: AppState) => state.register

export default registerSlice.reducer
