import dayjs from 'dayjs'
import { CustomerPayload, CustomerPayloadState, VisitType } from '@/types/register'
import { useEffect } from 'react'

if (typeof window !== 'undefined') {
	// @ts-ignore
	window['dayjs'] = dayjs
}

export enum REGISTER_SOURCE {
	LANDING = 1,
}

/**
 * Transforms the payload ready for sent to api
 */
export const transformPayload = ({
	address,
	customer,
	location,
	schedule,
	availability,
}: CustomerPayloadState): CustomerPayload => {
	const { days, hours } = schedule
	let isCoverage = false
	let firstDayVisit: string = ''
	let visitFrecuency : string=''
	let visit_days = []

	if (customer.type === 'PROSPECT') {
		firstDayVisit = dayjs().format('YYYY-MM-DD')
		visit_days = [
			{
				day: 'WEDNESDAY',
				start_time: '02:00:00',
				final_time: '03:00:00',
			},
		]
	} else {
		firstDayVisit = schedule.first_day_visit
		visitFrecuency =  Array.isArray(schedule.visit_frecuency) ? schedule.visit_frecuency[0] : schedule.visit_frecuency;
		isCoverage = true
		visit_days = days.map((day) => {
			return {
				day: day,
				start_time: dayjs(hours.start_time, 'HH:mm').format('HH:mm:ss'),
				final_time: dayjs(hours.final_time, 'HH:mm').format('HH:mm:ss'),
			}
		})
	}

	let firstAvailability = null
	if (availability && availability.length) {
		firstAvailability = availability[0]
	}

	return {
		type: customer.type,
		meet:customer.meet,
		contact: 'WHATSAPP_MESSAGE',
		status: 'IN_PROCESS',
		source: REGISTER_SOURCE.LANDING,
		visit_frequency:visitFrecuency,
		visit_day: visit_days,
		name: customer.name.trim(),
		last_paternal_name: customer.last_paternal_name.trim(),
		last_maternal_name: customer.last_maternal_name.trim(),
		date_birth: null,
		birthday_year: customer.birthday_year,
		phone_number: customer.phone_number,

		phone_number_whatsapp: customer.phone_number,
		phone: customer.phone_number,
		apartment: schedule.apartment,
		service: schedule.service,
		is_coverage: isCoverage,
		first_day_visit: firstDayVisit,

		street: address.street,
		street_1: address.street_1,
		street_2: address.street_2,
		code_postal: address.zip_code,
		ext_number: address.ext_number,
		int_number: address.int_number,
		suburb: address.colony,
		tower_depot_number: address.tower_depot_number,
		alcaldia: firstAvailability?.alcaldia,
		neighborhood: firstAvailability?.neighborhood,
		asenta: firstAvailability?.asenta,
		state: firstAvailability?.estado,

		// Geo
		lat: location.lat,
		lng: location.lng,

		gender: '',
		is_whatsapp: true,
		email: '',
		reference: '',
		another_apartment: '',
		apartment_photo: null,
	}
}

// window['createPayload'] = createPayload

const INPUT_FORMAT: string = 'HH:mm'
const OUTPUT_FORMAT: string = 'hh:mm a'

export const days_dict: { [value: string]: string } = {
	MONDAY: 'Lunes',
	TUESDAY: 'Martes',
	WEDNESDAY: 'Miércoles',
	THURSDAY: 'Jueves',
	FRIDAY: 'Viernes',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',
}

export const getDayNameOfVisit = (_days: VisitType[]): string => {
	const days: string[] = _days.map((item: VisitType) => {
		// @ts-ignore
		return days_dict[item.day]
	})

	return days && days.length ? days[0] : '-'
}

/**
 * Calcula el mensaje de resumen en base a los días seleccionados y las hora de inicio y fin de la entrega
 * e.g.
 * let message = createMessage(schedule.days, schedule.hours.start_time, schedule.hours.final_time)
 * console.log(message)
 *
 * @param _days
 * @param _start_time
 * @param _final_time
 */

export const createScheduleMessage = (_days: string[], _start_time: string, _final_time: string) => {
	const days: string[] = _days.map((day: string) => {
		// @ts-ignore
		return days_dict[day].toLowerCase()
	})

	let sdays: string = ''
	let nday: string = ''

	if (days.length === 1) {
		// el día

		const pluralDayName = ` ${days[0].toUpperCase()}${days[0].endsWith('o') ? 'S' : ''}`
		sdays = `${pluralDayName}`
		nday = '' + days.join()
	} else if (days.length > 1) {
		let last_item = days.pop()
		let str_days = days.join(', ')
		sdays = 'todos los días ' + (str_days + ' y ' + last_item)
	} else {
		sdays = 'todos los días'
	}

	_start_time = dayjs(_start_time, INPUT_FORMAT).format(OUTPUT_FORMAT)
	_final_time = dayjs(_final_time, INPUT_FORMAT).format(OUTPUT_FORMAT)

	const hours = `${_start_time} y  ${_final_time}`

	return {
		label: (
			<div>
				Te visitaremos los días <b>{sdays}</b> <br /> entre las <b>{hours}</b>
			</div>
		),
		sdays,
		hours,
		nday,
	}
}

/**
 * Calcula la fecha de la primera entrega en base a los días y hora seleccionados
 *
 * e.g.
 * console.log('Tu primera entrega es::', date.format(INPUT_FORMAT_DATE))
 *
 * @param days
 * @param final_time
 */
export const getDateOfFirstVisit = (days: string[], final_time: string) => {
	let gap: number = -2

	let nextDate = null

	let result = null

	let limitTime = '19:00'

	if (days.length > 0) {
		const currentDay = dayjs().format('MM DD') // Current day in format of MM DD

		const createDate = `${currentDay} ${final_time}`

		if (dayjs().format('dddd').toLocaleLowerCase() === days_dict[days[0]].toLowerCase()) {
			nextDate = dayjs(createDate, 'MM DD HH:mm:ss').add(gap, 'hours')

			const limitDate = dayjs(nextDate.format('YYYY-MM-DD ' + limitTime))

			// Sí la próx. fecha está antes de el límite de salida

			if (nextDate.isBefore(limitDate, 'hour')) {
				result = dayjs().add(7, 'days')
			} else {
				result = nextDate
			}
		} else {
			const dayOfWeekSelected = Object.keys(days_dict).indexOf(days[0]) + 1

			const dayOfWeekCurrent = dayjs().day()

			const delta = dayOfWeekSelected - dayOfWeekCurrent

			if (dayOfWeekSelected < dayOfWeekCurrent) {
				result = dayjs(createDate, 'MM DD HH:mm:ss').add(7 + delta, 'days')
			} else {
				result = dayjs(createDate, 'MM DD HH:mm:ss').add(delta, 'days')
			}
		}
	}

	return result?.format('YYYY-MM-DD')
}
