import { stringify } from 'query-string'
import { Billing, OrderPayload, VerifyViabilityType, ViabilityResponse } from '@/types/order'
import { endpoints } from '@/config/app'
import http from '@/services/http'

import type { CustomerPayload, CustomerResponse } from '@/types/register'
import type { AvailabilityType } from '@/components/DaysSelector/DaysSelector'
import { Apartment, State, AddressZip, Colony } from '@/types/register'
import { SaleSummary, DetailSaleSummaryData,DetailSaleSummary ,SaleDetailSummary} from '@/types/sale'

export const fetchCoverages = async (neighborhood: string, zip_code: string): Promise<AvailabilityType[]> => {
	return http.get(endpoints.coverages, {
		params: { neighborhood, zip_code },
		paramsSerializer: (params) => stringify(params, { arrayFormat: 'none', skipEmptyString: true }),
	})
}

export const createCustomer = async (payload: CustomerPayload): Promise<CustomerResponse> => {
	return http.post(endpoints.customers, payload)
}

export const updateCustomer = async (customerId: string, newDate: string): Promise<CustomerResponse> => {
	const url = `${endpoints.customers}${customerId}/`
	return http.patch(url, { first_day_visit: newDate })
}

export const updateCustomerData = async (customerId: string, payload: any): Promise<CustomerResponse> => {
	const url = `${endpoints.customers}${customerId}/`
	return http.patch(url, payload)
}

export const getCustomer = async (phone_number: string): Promise<CustomerResponse> => {
	return http.get(`${endpoints.customers}${phone_number}/`)
}

export const getCustomerById = async (customer_id: string): Promise<CustomerResponse> => {
	return http.get(`${endpoints.customers}?id=${customer_id}`).then((response) => {
		if (Array.isArray(response) && response.length === 1) {
			const [_customer] = response
			return _customer
		}

		return null
	})
}

export const getApartments = async (): Promise<Apartment[]> => {
	return http.get(`${endpoints.apartments}`)
}

export const getStates = async (): Promise<State[]> => {
	return http.get(`${endpoints.states}`)
}

export const getStateZipCodes = async (state: any): Promise<AddressZip[]> => {
	return http.get(`${endpoints.zipCodes}?${endpoints.state}=${state}`)
}

export const getColony = async (zipCode: any): Promise<Colony[]> => {
	return http.get(`neighborhood/?${endpoints.zipCode}=${zipCode}`)
}
export const getSalesSummary = async (): Promise<SaleSummary> => {
	return http.get(`${endpoints.sales_summary}views`)
}

export const getDetailSalesSummary = async (): Promise<SaleDetailSummary> => {
	return http.get(`${endpoints.sales_summary}`)
}

export const getDetailTeamSalesSummary = async (): Promise<any> => {
	return http.get(`${endpoints.team_sales_summary}`)
}


export const getSaleSummaryById = async (id: number): Promise<DetailSaleSummaryData> => {
	return http.get(`${endpoints.orders_delivery}${id}/products`)
}
export const sendSmsCode = async (phone_number: string): Promise<{ id: number; customer: number; deleted_at: any }> => {
	return http.post(endpoints.smsCode, {
		phone: phone_number,
	})
}

export const sendSmsCodeCampaing = async (phone_number: string): Promise<{ message: any}> => {
	return http.get(`${endpoints.smsCodeCampaing}${phone_number}`)
}


export const reSendSmsCodeCampaing = async (phone_number: string): Promise<{ message: any}> => {
	return http.post(`${endpoints.reSmsCodeCampaing}${phone_number}/`)
}


export const verifyCampaingCuopon= async (payload: any):Promise<{ data: any}> => {
	return http.post(`${endpoints.verifyCampaign}`,payload)
}

export const verifySecurityCode = async (phone_number: string, verification: string): Promise<{ valid: boolean }> => {
	return http.post(endpoints.verifyCode, {
		phone: phone_number,
		verification_code: verification,
	})
}

export const verifyViability = async (payload: VerifyViabilityType): Promise<ViabilityResponse> => {
	return http.post(endpoints.verifyViability, payload)
}

export const createOrder = async (payload: OrderPayload) => {
	return http.post(endpoints.orders, payload)
}

export const createBilling = async (payload: Billing) => {
	return http.post(endpoints.billing, payload)
}

export const createBillings = async (payload: Billing[]) => {
	const promises$ = payload.map((bill) => http.post(endpoints.billing, bill))
	return Promise.all(promises$)
}
