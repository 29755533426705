import React from 'react'
import Link from 'next/link'
import { AiOutlinePhone } from 'react-icons/ai'

export type Props = {
	phoneNumber: string
}

const SCLink: React.FC<Props> = ({ phoneNumber }) => {
	return (
		<Link href={`tel:${phoneNumber}`} replace={false}>
			<a className="btn-phone">
				<AiOutlinePhone className="text-white" size={38} />
			</a>
		</Link>
	)
}

export default SCLink
