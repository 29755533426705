import React from 'react'
import { Menu, Avatar, Dropdown, Typography, Space } from 'antd'
import type { MenuProps } from 'antd'
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons'
import { signOut } from 'next-auth/react'
import { FiChevronDown } from 'react-icons/fi'

type MenuItem = Required<MenuProps>['items'][number]

export function getMenuItem(
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group'
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
	} as MenuItem
}

type Props = {
	session: any
}

const AuthUser: React.FC<Props> = ({ session }) => {
	const { user } = session

	const items = [
		getMenuItem(
			<div key="item-1">
				<div className="text-gray-700">{user.name}</div>
				<div className="text-gray-400">{user.username}</div>
			</div>,
			'item-1'
		),
		getMenuItem(
			<div
				className="flex items-center gap-2"
				key="item-2"
				onClick={() => {
					signOut({
						callbackUrl: process.env.NEXT_PUBLIC_LOGIN_PATH,
					})
				}}
			>
				<PoweroffOutlined className="text-red-500" />
				<span className="text-red-500">Cerrar sesión</span>
			</div>,
			'item-2'
		),
	]

	return (
		<Dropdown overlay={<Menu items={items} />} trigger={['click']}>
			<Space className="auth-user">
				<Avatar style={{ backgroundColor: '#f56a00' }} icon={<UserOutlined />} />
				<div>{user.name}</div>
				<FiChevronDown />
			</Space>
		</Dropdown>
	)
}

export default AuthUser
